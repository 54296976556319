<!--
 * @Date: 2022-02-23 17:53:11
 * @LastEditTime: 2024-11-15 10:22:49
 * @Description: 
 * @FilePath: \szlsnk-user-mp\src\views\user\accountSettings\index.vue
-->
<template>
  <div>
    <!-- <van-cell title="头像" class="mt20">
      <template #right-icon>
        <default-image :url="user && user.avatar" is-avatar round width="35" height="35" />
      </template>
    </van-cell> -->
    <template v-if="token">
      <van-cell title="昵称" :value="user.name || user.phoneNum" />
      <van-cell title="手机号码" :value="user.phoneNum" />
      <!-- <van-cell title="修改密码" is-link to="/updatePwd" /> -->
      <!-- <van-cell title="微信" value="绑定" /> -->
      <div class="exit mt20">
        <span @click="loginOut">退出登录</span>
      <!-- <van-cell title="退出登录" @click="loginOut" /> -->
      </div>
    </template>
    <template v-else>
      <div class="exit mt20">
        <span style="color: #1485ff;" @click="gotoLogin">去登录</span>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "AccountSettings",
  data() {
    return {
      token:this.$store.getters.token
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    loginOut() {
      this.$store.commit("user/setName", "")
      this.$store.commit("user/setPhoneNum", "")
      this.$store.commit("user/setToken", "")
      this.$store.commit("user/setRefreshToken", "")
      this.$nextTick(() => {
        this.$router.replace("/")
      })
    },
    gotoLogin() {
      this.$router.push("/login")
    }
  }
}
</script>
<style scoped lang="less">
.exit {
  width: 100%;
  height: 106px;
  line-height: 106px;
  text-align: center;
  color: #dc3030;
  font-size: 30px;
  background: #ffffff;
}
</style>
